.message {
  padding: 10px;
  margin: 10px;
  width: fit-content;
}

.message-user {
  margin-left: auto;
  color: white;
  text-align: right !important;
}

.message-userCard {
  background-color: #0b81ff !important;

}

.message-guestCard {
  background-color: #e9e9eb !important;

}