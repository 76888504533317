.App {
  text-align: center;
}

.app-form {
  padding: 20px;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: #e9e9eb;
  width: 100%;
  margin: 20px;
}

.app-formControl {
  display: flex !important;
  flex-direction: row !important;
}

.app-input {
  flex: 0.95;
}

.app-iconButton {
  flex: 0;
}